import { useMedia } from 'react-use'

export function useBreakpoints() {
  const sm = useMedia('(min-width: 640px)')
  const md = useMedia('(min-width: 768px)')
  const lg = useMedia('(min-width: 1024px)')
  const xl = useMedia('(min-width: 1280px)')
  // mobile is smaller than md
  const isMobile = useMedia('(max-width: 639px)')
  // tablet is between md and lg
  const isTablet = useMedia('(min-width: 640px) and (max-width: 1024px)')
  // desktop is larger 1024px
  const isDesktop = useMedia('(min-width: 1024px)')
  return { sm, md, lg, xl, isTablet, isDesktop, isMobile }
}
